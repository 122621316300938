import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

import { MuiDialogActions } from '@system/theme/overrides/MuiDialogActions';
import { MuiDialogTitle } from '@system/theme/overrides/MuiDialogTitle';

import { MuiAlert } from './MuiAlert';
import { MuiAutocomplete } from './MuiAutocomplete';
import { MuiButton } from './MuiButton';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiChip } from './MuiChip';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiDialog } from './MuiDialog';
import { MuiFormControlLabel } from './MuiFormControlLabel';
import { MuiInputBase } from './MuiInputBase';
import { MuiInputLabel } from './MuiInputLabel';
import { MuiLink } from './MuiLink';
import { MuiListItemButton } from './MuiListItemButton';
import { MuiRadio } from './MuiRadio';
import { MuiSlider } from './MuiSlider';
import { MuiTooltip } from './MuiTooltip';
import { MuiGrid } from '@system/theme/overrides/MuiGrid';
import { MuiDialogContent } from '@system/theme/overrides/MuiDialogContent';
import { MuiStack } from '@system/theme/overrides/MuiStack';
import { MuiTableContainer } from '@system/theme/overrides/MuiTableContainer';
import { MuiList } from '@system/theme/overrides/MuiList';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiRadio,
  MuiLink,
  MuiAutocomplete,
  MuiInputLabel,
  MuiInputBase,
  MuiCssBaseline,
  MuiButton,
  MuiCheckbox,
  MuiAlert,
  MuiSlider,
  MuiListItemButton,
  MuiDialog,
  MuiChip,
  MuiFormControlLabel,
  MuiDialogActions,
  MuiDialogTitle,
  MuiTooltip,
  MuiGrid,
  MuiDialogContent,
  MuiStack,
  MuiTableContainer,
  MuiList,
};
