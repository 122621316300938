import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiTableContainer: Components<Theme>['MuiTableContainer'] = {
  styleOverrides: {
    root: {
      '&::-webkit-scrollbar': { width: '5px', height: '5px' },
      '&::-webkit-scrollbar-track': {
        background: '#F6F6F6',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#BBC0CB',
        border: 'none',
        borderRadius: '50px',
      },
    },
  },
};
